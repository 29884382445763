const orderAsks = [
    "Lemme get a",
    "Make me a",
    "Have you heard of a",
    "I want a",
    "Pour me a",
    "Hey bartender, I need a"
]

export const explanation = "Our tech is guaranteed to generate only the best cocktails, cocktails made specifically for you! We have leveraged the most advanced Natural Language Processing algorithm (GPT3) and totally ruined it by teaching it to make bad drinks."
export default orderAsks;